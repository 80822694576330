import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import AboutMe from "./ForegroundText/AboutMe";
import Contact from "./ForegroundText/Contact";
import Projects from "./ForegroundText/Projects";
import ErrorPage from "./ErrorPage";
import Technology from "./ForegroundText/Technology";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App></App>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "about-me",
                element: <AboutMe/>
            },
            {
                path: "contact",
                element: <Contact/>
            },
            {
                path: "projects",
                element: <Projects/>
            },
            {
                path: "technologies",
                element: <Technology/>
            }

        ]
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);


