import React from 'react';
import '../App.css';
import {Link} from "react-router-dom";

function Technology() {
    return (
        <div className="Typewriter">
            <h1>Technologies</h1>
            <h2>Languages</h2>
            <p>Python, C#, Javascript, PHP, Java, German, English</p>
            <h2>Frameworks</h2>
            <p>Django, Tensorflow, .NET, React, Kubernetes, Docker, Scrum, AWS, Azure</p>
            <h2>Jobs</h2>
            <p>Software Developer, Engineer, Architect and Consultant</p>
            <Link to="../about-me">Back</Link>
        </div>
    );
}

export default Technology;
