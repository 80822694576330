import React from 'react';
import '../App.css';

function Projects() {
    return <>
        <h1>Projects</h1>
        <p>These are some of the projects I made or contributed to and companies I worked with:</p>
        <ul>
            <li><a href="https://moneymachine.monster" rel="noopener">Moneymachine.monster</a></li>
            <li><a href="https://berta-app.com" rel="noopener">BERTA</a></li>
            <li><a href="https://www.shake-friends.com" rel="noopener">Shakefriends</a></li>
            <li><a href="https://tui.com" rel="noopener">Tui.com</a></li>
            <li><a href="https://imagecontent.de" rel="noopener">imagecontent.de</a></li>
        </ul>

        <p>
            You can also find me here:
        </p>
        <div className="External-logos">
            <a href="https://github.com/patchontheedge" rel="noopener"><img
                src={require('../img/github-mark.svg').default}
                className="github"
                alt="github-logo"/></a>
        </div>
    </>
}

export default Projects;
