import React from "react";

export function Background() {
    return <div className="Background">
        <img src={require("./img/sun.svg").default} className="Circle" alt="logo"/>
        <div className="Background-sky"/>
        <div className="Background-sea"/>
        <div className="Background-beach"/>
    </div>;
}
export default Background;