import React from 'react';
import '../App.css';

function Contact() {
    return <>
        <h1>Contact</h1>
        <p>
            Feel free to contact me if you need help with your software or IT in general.
        </p>
        <p>
            Phone: +49 151 25 30 37 14
        </p>
        <p>
            E-Mail: <a href="mailto:mail@patrick-probst.de">mail@patrick-probst.de</a>
        </p>
        <p>
            Patrick Probst <br/>
            Landwehrstraße 57 <br/>
            30519 Hannover<br/>
        </p>
    </>
}

export default Contact;
