import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    let status = "";
    let  msg = ""
    if(isRouteErrorResponse(error)){
        status = error.statusText;
        if(error.error)
            msg = error.error.message;
    }
    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <h2>{status}</h2>
                <h3>{msg}</h3>
                </p>
        </div>
    );
}
