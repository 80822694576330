import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import {Background} from "./Background";
import {Outlet, useNavigate} from "react-router-dom";

function App() {
    const foregroundRef = useRef<HTMLDivElement>(null);
    const pictureRef = useRef<HTMLImageElement>(null);
    const mainLinkRef = useRef<HTMLAnchorElement>(null);
    const [appClasses, setAppClasses] = useState("App Cursor-Pointer");
    const [headingClasses, setHeadingClasses] = useState("Heading");
    const [foregroundClasses, setForegroundClasses] = useState("Foreground Hide");
    const navigate = useNavigate();

    function profilePicClicked() {
        if (pictureRef.current && window.location.pathname === "/") {
            pictureRef.current.className = "Profile-Picture-Gone";
            setForegroundClasses("Foreground");
            navigate("/about-me");
        }
    }

    useEffect(() => {
        if (window.location.pathname !== "/") {
            setAppClasses("App");
            setForegroundClasses("Foreground");
            if (pictureRef.current)
                pictureRef.current.className = "Profile-Picture-Gone";
            setHeadingClasses("Hide");
            if (mainLinkRef.current) {
                mainLinkRef.current.href = "https://google.de";
                console.log("test");
            }
        }
        if (window.location.pathname === "/technologies")
            setForegroundClasses("Foreground Techstack");
    });

    return (
        <div className={appClasses} onClick={profilePicClicked}>
            <Background/>
            <div className={headingClasses}>
                <h1>Patrick Probst</h1>
                <h2>Software Developer</h2>
            </div>
            <div className={foregroundClasses} ref={foregroundRef}>
                <div className="Buttons Cursor-Pointer">
                    <div className="Button" onClick={() => {
                        navigate("about-me")
                    }}>P
                    </div>
                    <div className="Button" onClick={() => {
                        navigate("contact")
                    }}>C
                    </div>
                    <div className="Button" onClick={() => {
                        navigate("projects")
                    }}>P
                    </div>
                </div>
                <div className="Text-outlet"><Outlet/></div>

            </div>
            <img src={require('./img/Bild.png')} className="Profile-Picture" alt="Patrick Probst"
                 ref={pictureRef}/>
        </div>
    );
}

export default App;
