import React from 'react';
import '../App.css';
import {Link} from "react-router-dom";

function AboutMe() {
    return <>
        <h1>Patrick Probst</h1>
        <p>
            Hi there! I'm Patrick C. Probst, Software Engineer from Hannover, Germany.
        </p>
        <p>
            Feel free to have a look around, e.g. at the <Link to="../technologies">technologies</Link> I am using.
        </p>

    </>
}

export default AboutMe;
